<template>
  <div class="course-detail">
    <div class="header">
      <div class="container-header">
        <div class="course-item">
          <div class="course-content">
            <div class="course-img">
              <img :src="courseInfo.logo2" alt="" />
            </div>
            <div class="course-content-item">
              <div class="course-name">
                {{ courseInfo.name }}
              </div>
              <div class="course-descr">
                {{ courseInfo.descr }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 课程列表 -->
      <div class="container">
        <!-- 课程列表 -->
        <div style="display: flex">
          <div
            @click="showClass = 0"
            :class="['row-name', showClass === 0 ? 'select' : 'notSelect']"
          >
            题目列表
          </div>
          <div
            @click="updatePage(1)"
            :class="['row-name', showClass === 1 ? 'select' : 'notSelect']"
            id="answeringQuestionList"
          >
            我的错题
          </div>
          <div
            @click="updatePage(2)"
            :class="['row-name', showClass === 2 ? 'select' : 'notSelect']"
            id="answeringQuestionList"
          >
            我的收藏
          </div>
        </div>
        <!-- 班级列表 -->
        <div v-if="showClass === 0">
          <div
            class="class-item"
            v-for="(item, index) in subjects"
            :key="index"
          >
            <div class="class-item-name">{{ item.name }}</div>
            <div class="class-item-topicNum">{{ item.topicNum }}道题</div>
            <el-button
              type="primary"
              round
              size="small"
              class="class-item-button"
              @click="startLearning(item, 1)"
              >练习模式
            </el-button>
            <el-button
              type="danger"
              round
              size="small"
              @click="startLearning(item, 2)"
              class="class-item-button"
              >考试模式
            </el-button>
          </div>
        </div>
        <!-- 我的错题 -->
        <div v-else-if="showClass === 1 && error.showList">
          <div
            style="padding: 20px 0;text-align: right;"
            v-if="errors.list.length > 0"
          >
            <el-popconfirm
              title="是否删除所有错题？"
              @confirm="deleteAllErrors"
              :disabled="loading"
            >
              <el-button type="danger" plain slot="reference"
                >删除所有</el-button
              >
            </el-popconfirm>
          </div>
          <div class="course" v-if="errors.list.length > 0" v-loading="loading">
            <div
              class="course-list"
              v-for="(item, index) in errors.list"
              :key="index"
            >
              <!-- <p @click="viewDetail(item, showClass)"></p> -->
              <!-- 删除错题按钮 -->
              <el-button
                style="float: right; margin: 0 15px"
                type="primary"
                icon="el-icon-delete"
                @click="deleteTopicError(item, $event)"
              ></el-button>

              <div
                class="course-list-content html_value"
                v-html="item.title"
                @click="viewDetail(item, showClass)"
              ></div>
              <div class="divider">
                <el-divider></el-divider>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="error.limit"
                :total="errors.total"
                @current-change="errorCurrentChange"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!-- 我的收藏-->
        <div v-else-if="showClass === 2 && collect.showList">
          <div class="course" v-if="collects.list.length > 0">
            <div
              class="course-list"
              v-for="(item, index) in collects.list"
              :key="index"
              @click="viewDetail(item, showClass)"
            >
              <div
                class="course-list-content html_value"
                v-html="item.title"
              ></div>
              <div class="divider">
                <el-divider></el-divider>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="collect.limit"
                :total="collects.total"
                @current-change="collectCurrentChange"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!-- 查看题目详情 -->
        <div
          class="body-content"
          v-if="
            (showClass === 1 && !error.showList) ||
              (showClass === 2 && !collect.showList)
          "
        >
          <div class="body-content-img">
            <img
              src="../../assets/back-left.png"
              alt=""
              width="30px"
              height="30px"
              @click="back(showClass)"
            />
          </div>
          <div class="practicing">
            <div v-if="subjectInfo.type === 1" class="practicing-type">
              单选题
            </div>
            <div v-if="subjectInfo.type === 2" class="practicing-type">
              多选题
            </div>
            <div v-else-if="subjectInfo.type === 3" class="practicing-type">
              简答题
            </div>
            <div class="practicing-content">
              <div
                class="practicing-title html_value"
                v-html="subjectInfo.title"
              ></div>
              <!-- 单选题、多选题 -->
              <div v-if="subjectInfo.type == 1 || subjectInfo.type == 2">
                <div
                  class="practicing-option"
                  v-for="(item, index) in subjectInfo.topicOptions"
                  :key="index"
                  :class="[
                    subjectInfo.answer && subjectInfo.answer.includes(item.opt)
                      ? 'select2'
                      : subjectInfo.yourAnswer &&
                        subjectInfo.yourAnswer.includes(item.opt)
                      ? 'error2'
                      : 'no-select',
                  ]"
                >
                  <span v-if="!item.isClick" style="margin-right: 10px">{{
                    item.opt
                  }}</span>
                  <img
                    v-else-if="item.isClick"
                    src="../../assets/true.png"
                    width="24px"
                    height="24px"
                  />
                  <span class="html_value" v-html="item.value"></span>
                </div>
              </div>
              <div v-else style="margin-top: 10px">
                <el-input
                  v-if="showClass === 1"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="subjectInfo.yourAnswer"
                  :disabled="true"
                >
                </el-input>
              </div>
              <div
                class="practicing-answer"
                :style="{ width: showClass === 1 ? '335px' : '165px' }"
                v-if="subjectInfo.type !== 3"
              >
                <div class="practicing-answer-div" v-if="showClass === 1">
                  <div class="practicing-answer-text">选择答案</div>
                  <div
                    class="practicing-answer-option"
                    :style="{
                      color: subjectInfo.correct == 0 ? '#FF4343' : '#4394ff',
                    }"
                  >
                    {{ subjectInfo.yourAnswer ? subjectInfo.yourAnswer : "无" }}
                  </div>
                </div>
                <div
                  class="practicing-answer-div"
                  :style="{ width: showClass === 1 ? '50%' : '100%' }"
                >
                  <div class="practicing-answer-text">正确答案</div>
                  <div
                    class="practicing-answer-option"
                    v-html="subjectInfo.answer"
                  ></div>
                </div>
              </div>

              <div class="practicing-analysis">
                <span class="analytical-title">{{
                  subjectInfo.type === 3 ? "本题答案" : "本题解析"
                }}</span>
                <p
                  class="html_value"
                  v-if="subjectInfo.type === 3"
                  v-html="subjectInfo.answer"
                ></p>

                <span class="analytical-title" v-if="subjectInfo.type === 3">{{
                  "本题解析"
                }}</span>
                <p
                  v-if="subjectInfo.analysis !== ''"
                  v-html="subjectInfo.analysis"
                ></p>
                <p v-else>无</p>

                <!-- <span class="analytical-title">{{ subjectInfo.type === 3 ? '本题答案' : '本题解析' }}</span>
                <p class="html_value" v-if="subjectInfo.type === 3" v-html="subjectInfo.answer"></p>
                <p class="html_value" v-else v-html="subjectInfo.analysis"></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getListByCourse,
  getTopicErrorInfo,
  findCourseById,
  topicCollectListPage,
  deleteError,
  topicCollectInfo,
  getTopicErrorList,
  existTestRecord,
  deleteAllError,
} from "@/api/course";
export default {
  data() {
    return {
      classType: "",
      id: 0,
      courseInfo: {},
      classList: [],
      subjects: [],
      showClass: 0,
      collect: {
        page: 1,
        limit: 10,
        showList: true,
      },
      collects: {},
      subjectInfo: {
        // 一道题目的信息
        testResultVo: {
          answer: "",
        },
      },
      errors: {},
      error: {
        page: 1,
        limit: 10,
        showList: true,
      },
      textareaValue: "",
      loading: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getListByCourse(this.id);
    this.getTopicError();
    this.getList();
  },
  methods: {
    // 获取题目列表
    getListByCourse(id) {
      getListByCourse(id).then((res) => {
        if (res != undefined) {
          this.subjects = res.data;
        }
      });
      findCourseById({ id: this.id }).then((res) => {
        console.log("14");
        if (res.code == 200) {
          this.courseInfo = res.data;
          return res;
        }
      });
    },
    // 删除所有错题
    async deleteAllErrors() {
      this.loading = true;
      let res = await deleteAllError();
      if (res.code == 200) {
        this.getTopicError();
        this.loading = false;
        this.$message.success("删除成功");
      }
    },
    //删除错题
    async deleteTopicError(id, e) {
      try {
        console.log(id);
        await this.$confirm("此操作将永久删除该文件，是否继续？");
        await deleteError(id.topicId);
        this.getTopicError();
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 获得错题收藏的数据
     */
    getTopicError() {
      this.loading = true;
      getTopicErrorList({
        title: this.keyword,
        page: this.error.page,
        limit: this.error.limit,
      }).then((res) => {
        if (res && res.code == 200) {
          this.errors = res.data;
          this.loading = false;
        }
      });
    },
    // 获得收藏的题目
    getList() {
      topicCollectListPage({
        page: this.collect.page,
        limit: this.collect.limit,
      }).then((res) => {
        var list = res.data;
        this.collects = list;
      });
    },
    // 查看题目详情
    viewDetail(item, type) {
      if (type === 1) {
        // 我的错题
        getTopicErrorInfo(item.id).then((res) => {
          if (res && res.code == 200) {
            this.error.showList = false;
            this.subjectInfo = res.data;
          }
        });
      } else {
        // 我的收藏
        topicCollectInfo({ id: item.id }).then((res) => {
          if (res && res.code == 200) {
            this.collect.showList = false;
            this.subjectInfo = res.data;
          }
        });
      }
    },
    back(type) {
      if (type === 1) {
        this.error.showList = true;
      } else {
        this.collect.showList = true;
      }
    },
    updatePage(showClass) {
      this.error.showList = true;
      this.collect.showList = true;
      this.showClass = showClass;
    },
    collectCurrentChange(page) {
      this.collect.page = page;
      this.getList();
    },
    errorCurrentChange(page) {
      this.error.page = page;
      this.getTopicError();
    },
    // 点击开始学习
    startLearning(item, id) {
      item.userId = this.id;
      item.type = id; //2代表考试模式,1练习模式
      if (item.topicNum === 0) {
        this.$message("未关联题目");
      } else {
        console.log(item);
        if (item.type == 2) {
          // 如果是考试模式直接跳转
          this.$router.push({ path: "/startPracticing", query: item });
        } else {
          existTestRecord(item.id)
            .then((res) => {
              console.log(res);
              if (res.data == 0) {
                return this.$router.push({
                  path: "/startPracticing",
                  query: item,
                });
              }
              this.$confirm("上次答题未完成,是否继续上次答题?", "提示", {
                distinguishCancelAndClose: true,
                cancelButtonText: "重新答题",
                confirmButtonText: "继续答题",
                type: "warning",
              })
                .then(() => {
                  item.type = 3;
                  this.$router.push({ path: "/startPracticing", query: item });
                })
                .catch((action) => {
                  if (action == "cancel") {
                    this.$router.push({
                      path: "/startPracticing",
                      query: item,
                    });
                    return;
                  }
                });
            })
            .catch(() => {
              this.$router.push({ path: "/startPracticing", query: item });
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.html_value {
  text-align: left;
  width: 95%;

  /deep/ img {
    width: 95%;
    margin: 5px 0;
  }

  /deep/ table {
    width: 95%;
    font-size: 12px;
  }

  /deep/ p {
    margin: unset;
  }
}

.analytical-title {
  padding-left: 15px;
  border-left: 3px solid #4394ff;
}

.pagination {
  text-align: center;
  padding: 20px 0;
}

.course-list {
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
  margin-top: 24px;

  .course-list-content {
    padding: 0 30px;
  }

  .divider {
    padding: 0 10px;
  }
}

.error2 {
  color: #ff6060;
}

.select2 {
  color: #4394ff;
}

.select {
  color: #4394ff;
  border-bottom: 5px solid #4394ff;
}

.notSelect {
  color: black;
  border-bottom: none;
}

.row-name {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 400;
  margin-right: 35px;
  padding-bottom: 4px;
  cursor: pointer;
}

.course-detail {
  .line {
    height: 5px;
    width: 60px;
    margin: 4px 0 30px 0;
    background-color: #4394ff;
    border-radius: 5px;
  }

  .class-item {
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    line-height: 30px;

    .class-item-name {
      width: 700px;
    }

    .class-item-topicNum {
      font-size: 16px;
      font-weight: 400;
      color: #4394ff;
      width: 70px;
      margin-right: 305px;
      text-align: right;
    }
  }

  .header {
    width: 100%;
    background-color: #323368;

    .container-header {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0 80px 0;
      display: flex;

      .course-item {
        // border: 1px solid #EEEEEE;
        width: 100%;
        padding: 20px;
        margin-bottom: 23px;

        .course-type-name {
          margin-bottom: 15px;
        }

        .course-content {
          display: flex;

          .course-content-item {
            margin-left: 20px;
            flex: 1;

            .course-name {
              margin-bottom: 13px;
              font-size: 18px;
              color: #ffffff;
            }

            .course-descr {
              color: #a7a7a7;
            }
          }
        }
      }
    }
  }
}

.course-img {
  width: 240px;
  height: 170px;
  overflow: hidden;
}

.course-img img {
  max-width: 240px;
  height: 170px;
  width: 240px;
}

.body-content {
  margin-left: 30px;

  .body-content-img {
    margin: 20px 0;
    cursor: pointer;
  }

  .row-name {
    margin-top: 30px;
    font-size: 20px;
  }

  .practicing {
    margin-top: 6px;
    display: -webkit-inline-box;
    font-weight: 400;
    margin-bottom: 120px;

    .practicing-type {
      font-size: 12px;
      color: #fff;
      background-color: #4394ff;
      width: 46px;
      height: 21px;
      border-radius: 11px;
      line-height: 21px;
      text-align: center;
      margin-right: 10px;
    }

    .practicing-content {
      width: 100%;
      font-size: 20px;
      color: #000000;
      margin-right: 80px;

      .practicing-option {
        margin-top: 20px;
        display: flex;
      }

      .view-analysis {
        font-size: 18px;
        font-weight: 400;
        color: #4394ff;
        margin-top: 50px;
        display: flex;
        align-items: center;
      }

      .practicing-answer {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        background-color: #acacac;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(152, 152, 152, 0.16);

        .width-335 {
          width: 335px;
        }

        .width-165 {
          width: 365px;
        }

        .practicing-answer-div {
          font-weight: 400;
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 10px;

          .practicing-answer-text {
            font-size: 18px;
            color: #000000;
            line-height: 28px;
            flex: 1;
          }

          .practicing-answer-option {
            font-size: 26px;
            color: #4394ff;
            flex: 6;
          }
        }
      }

      .practicing-analysis {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        margin-top: 15px;
      }
    }

    .practicing-time {
      width: 315px;
      height: 368px;

      .practicing-time-clock {
        width: 100%;
        height: 86px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .practicing-time-clock-text {
          font-size: 30px;
          font-weight: 400;
          color: #4394ff;
          margin-left: 3px;
        }
      }

      .practicing-time-option {
        margin-top: 10px;
        height: 368px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        padding: 15px 20px 0 20px;
        position: relative;

        .option-number {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }

        .option-line {
          height: 1px;
          background-color: #dcdfe6;
        }

        .option-bottom {
          position: absolute;
          bottom: 0;
          width: 265px;

          .option-bottom-page {
            text-align: center;
            margin-top: 30px;
          }

          .option-bottom-submit {
            margin: 20px auto;
            font-size: 18px;
            font-weight: 400;
            color: #4394ff;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
